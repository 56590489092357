@import "../../theme.module.scss";

.checkbox_container {
    font-size: 18px;
}

.radio_container {
    font-size: 18px;
}

.chart {
    // display: flex;
    // align-items: center;
    // justify-content: center;
}

.chart_section {
    display: flex;
    flex-direction: row;
    // width: 80%;
    // background-color: red;
    margin-bottom: 30px;
}

.chart_label {
    flex: 1.5;
    font-size: 22px;
}

.chart_inputs {
    flex: 3;
}

$savedBackgroundColor: #e8f8f5; 
$unsavedBackgroundColor: rgb(255, 248, 215);
$disabledBackgroundColor: rgb(223, 223, 223);
$accordionButtonTextColor: rgba(0, 0, 0, 0.50);

.accordionButtonSaved.collapsed, .accordionButtonSaved:not(.collapsed) {
    background-color: $savedBackgroundColor;
    color: var(--bs-primary);
}

.accordionButtonSaved:not(.collapsed):disabled {
    background-color: $disabledBackgroundColor;
    color: $accordionButtonTextColor;
}


.accordionButtonUnsaved.collapsed, .accordionButtonUnsaved:not(.collapsed) {
    background-color: $unsavedBackgroundColor;
    color: $accordionButtonTextColor;
}

.accordionButtonUnsaved:not(.collapsed):disabled {
    background-color: $disabledBackgroundColor;
    color: $accordionButtonTextColor;
}


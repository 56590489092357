$width: 300px;
$height: 100px;
$backgroundColor: rgb(255, 255, 255);
$borderRadius: 3px;

.container {
    z-index: 4000;
    position: fixed;
    width: $width;
    height: $height;
    // hide
    bottom: calc($height * -1);
    opacity: 0;
    left: 50%;
    margin-top: calc( $height / 2 * -1) ;
    margin-left: calc(calc( $width / 2) * -1);
    background-color: $backgroundColor;
    border-radius: $borderRadius;
    box-shadow: 0 0 10px #676767;
    transition: all .35s ease-in-out;
}

.typeMessage {
    display: flex;
}

.type {
    width: 10px;
    height: $height;
    border-radius: $borderRadius 0 0 $borderRadius;
}

.success {
    @extend .type;
    background-color: var(--bs-primary);
}

.warn {
    @extend .type;
    background-color: rgb(255, 204, 0);
}

.error {
    @extend .type;
    background-color: rgb(222, 70, 70);
}

.message {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1;
    
}

.show {
    bottom: 20px;
    opacity: 1;
}

.hide {
    bottom: calc($height * -1);
    opacity: 0;
}
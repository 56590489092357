.fullScreenTransparent {
    z-index: 2000;
    background: rgba(74, 74, 74, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.fullScreenContent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.loadingSpinner {
    width: 10vw;
    height: 10vw;
}
$rowColor1Heading: var(--bs-secondary);
$rowColor2Heading: var(--bs-primary);
$rowColor3Heading: var(--bs-gray-200);
$rowColor1Data: white;
$rowColor2Data: var(--bs-gray-100);
$rowColor3Data: var(--bs-gray-300);

$color1Light: rgba(255, 255, 255, 0.90);
$screen-break: 959px;

.chartTable {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
}
.chartTable thead tr th,
.chartTable thead tr td {
    padding: 0.5vw;
    font-size: 1vw;
    border: 1px solid $rowColor1Heading;
    text-align: center;


    @media screen and (max-width: $screen-break) {
        font-size: 2vw;
        padding: .5vw;
    }
}


.chartTable thead tr:nth-child(1) {
    background-color: $rowColor1Heading;
    color: $color1Light;
}

.chartTable thead tr:nth-child(1) th {
    font-size: 2vw;
    padding: .8vw;
    
    @media screen and (max-width: $screen-break) {
        font-size: 4vw;
        padding: 2vw;
    }
}


.chartTable thead tr:nth-child(2) {
    background-color: $rowColor2Heading;
    color: $color1Light;
}


.chartTable thead tr:nth-child(3) {
    background-color: $rowColor3Heading;
}


.chartTable thead tr:nth-child(3) th {
    font-size: .7vw;
    padding: 6px;
    @media screen and (max-width: $screen-break) {
        font-size: 1.5vw;
        // padding: 1vw;
    }
    // writing-mode: vertical-lr;
    // transform: rotate(-180deg);
    // text-orientation: sideways;
}

.chartTable tbody tr:nth-child(odd) {
    background-color: $rowColor1Data;
}


.chartTable tbody tr:nth-child(even) {
    background-color: rowColor2Data;
}


.chartTable tbody tr td {
    padding: .5vw;
    font-size: 1vw;
    border: 1px solid $rowColor1Heading;
    text-align: center;

    @media screen and (max-width: $screen-break) {
        font-size: 2vw;
        padding: 1vw;
    }
}

.chartTable tbody tr:hover {
    background-color: $rowColor3Data;
}

.rhFormTitle {
    font-size: x-large;
    margin: 30px 0;
}


.rhForm>div>label {
    width: 200px;
}

.rhForm>div {
    margin-bottom: 30px;
}


/* Customize the label (the container) */
.checkbox_container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkbox_checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox_container:hover input~.checkbox_checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox_container input:checked~.checkbox_checkmark {
    background-color: var(--bs-primary);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox_checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox_container input:checked~.checkbox_checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox_container .checkbox_checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}



/* Customize the label (the container) */
.radio_container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radio_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.radio_checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio_container:hover input~.radio_checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio_container input:checked~.radio_checkmark {
    background-color: var(--bs-primary);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio_checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio_container input:checked~.radio_checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio_container .radio_checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}